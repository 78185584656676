/* Specific styles - TODO generalize styles to helpers.css */

/* Tour Badge */

@media screen and (max-width: 654px) {
  .home-price {
    min-width: 45px;
  }
}
@media screen and (min-width: 655px), print {
  .home-price {
    min-width: 39px;
  }
}

/* Social media icons */

@media screen and (min-width: 655px), print {
  .social {
    height: 20px;
    width: 20px;
  }
}

/* Availability */

.availability .is-yellow {
  background: #fff2c9;
}
.availability .is-blue {
  background: #d4ebf2;
}
.availability .is-pink {
  background: #f8e8ec;
}

/* Availability Calendar */

.availability .calendar {
  --cell-gap-x: calc(1.5 * var(--spacing));
  --cell-gap-y: calc(1.5 * var(--spacing));
  display: grid;
  grid-template-columns: min-content 1fr;
}

.availability .calendar .days {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.availability .calendar strong,
.availability .calendar .days > span {
  padding: var(--cell-gap-y) var(--cell-gap-x);
}
.availability
  .calendar
  .days
  > span.is-outlined:not([class*="has-background-"]) {
  --border-width: 2px;
  border: var(--border-width) solid var(--backdrop);
  padding: calc(var(--cell-gap-y) - var(--border-width))
    calc(var(--cell-gap-x) - var(--border-width));
}

.availability .calendar .invite-label {
  grid-column: 1 / -1;
}

/* iPhone 5/5S/SE 1 */
@media screen and (max-width: 360px) {
  .availability .calendar {
    --cell-gap-x: var(--spacing);
    --cell-gap-y: var(--spacing);
  }
}

/* Desktop */
@media screen and (min-width: 655px), print {
  .availability .calendar {
    --cell-gap-x: 2.5px;
    --cell-gap-y: var(--spacing);
  }
}

@media screen and (min-width: 655px), print {
  .availability .tax-and-fees {
    padding: 4px;
  }
}

/* Availability Popup */

@media screen and (min-width: 655px), print {
  .availability-popup {
    margin-left: calc(8 * var(--spacing));
    min-width: 305px; /* to prevent line wrap due to See Closest Dates */
  }

  .availability-popup .availability-spinner {
    height: 17px; /* to prevent content jump b/w check availability */
  }
}

/* Availability Modal */

.room-guests-count {
  min-width: 64px;
}

/* Nearby Dates Available */

.nearby-date {
  width: 33.33333%;
  min-height: 47px;
}

/* Tour Page */

.tour-page-main-wrapper .note {
  border: 1px dotted #999;
}

@media screen and (max-width: 654px) {
  .tour-page-main-wrapper > .column:nth-child(1),
  .tour-page-main-wrapper > .column:nth-child(3) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

/* Desktop and print (override) */
@media screen and (min-width: 655px), print {
  .tour-page-main-wrapper {
    display: block;
  }
  .tour-page-main-wrapper > .column {
    float: left;
  }
}

/* Open/Close All */

#open-all-button {
  font-size: 0.92rem;
}

/* Print */

@media print {
  .availability {
    /* Preserve colors when printing, see #519 */
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}
