/* Overwrites */

:root {
  font-size: 100%;
}

/* Remove excessive margin from the clock icon in <input type="time" /> */
input[type="time"]::-webkit-calendar-picker-indicator {
  margin-left: 0;
}

.input,
.select,
.textarea,
.react-tel-input .input.form-control {
  height: auto;
  font-size: 1rem;
  font-weight: normal;
  padding-top: var(--spacing);
  padding-bottom: var(--spacing);
  color: var(--black);
}

.button {
  padding: var(--spacing);
}
.button.is-light-blue[disabled],
.button.is-yellow[disabled] {
  background: var(--dark-gray);
  border-color: var(--dark-gray);
  color: var(--white);
}

/* Specific */

.availability .calendar {
  --cell-gap-x: calc(2 * var(--spacing));
  --cell-gap-y: calc(2 * var(--spacing));
  max-height: calc(100vh - 386px);
}

.stack {
  height: 17px;
  width: 17px;
  border-radius: 1px;
}

.stack-button {
  width: 23px;
  height: 23px;
}

.danno-modal .modal-box {
  min-width: 700px;
  min-height: 700px;
}

/* Helpers */

.is-aspect-ratio-golden {
  aspect-ratio: 1.61803;
}
